@tailwind base;
@tailwind components;
@tailwind utilities;

/*
For development:
npx tailwindcss -i ./src/input.css -o ./src/output.css --watch

For Production:
npx tailwindcss -o ./src/output.css --minify
*/

.info-maxheight {
	max-height: calc(100vh - 100px - 75px - 6.5rem);
}
.w-vw67{
	width: 66vw!important;
}
.w-vw33{
	width: 33vw!important;
}