/* Main container styling */
.game-tab-view {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-color: #333842;
	color: #fff;
	font-family: Arial, sans-serif; /* using a general font, but feel free to choose any */
}

/* Tab buttons styling */
.tabs {
	display: flex;
	height: 50px;
	border-bottom: 1px solid #454950;
}

.tabs button {
	flex: 1; /* This makes the buttons take up equal width */
	padding: 12px 16px;
	margin: 0 5px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	background-color: transparent;
	transition: background-color 0.3s, transform 0.2s;
	color: #fff;
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.tabs button:hover {
	background-color: #454950;
	transform: translateY(-2px); /* Slight lift effect */
}

.tabs button.active {
	background-color: #555a64;
	transform: translateY(0);
}

/* Content area styling */
.content {
	flex-grow: 1;
	padding: 20px;
	height:100%-50px;
}

.ide {
	height: 100%;
}

/* Specific styling for the PythonEditor and other components */
.script-editor {
	border: 1px solid #454950;
	border-radius: 4px;
	padding: 16px;
}

.log-viewer {
	border: 1px solid #454950;
	border-radius: 4px;
	padding: 16px;
	text-align: left;
	overflow-y: scroll;
	flex-grow: 1;
}

input[type="text"] {
	width: 100%;
	padding: 8px;
	margin-bottom: 10px;
	border: 1px solid #454950;
	border-radius: 4px;
	background-color: #454950;
	color: #fff;
}

Button {
	padding: 8px 16px;
	border: none;
	border-radius: 4px;
	background-color: #555a64;
	color: #fff;
	cursor: pointer;
	transition: background-color 0.3s;
}

Button:hover {
	background-color: #666c76;
}

table {
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 1rem;
	background-color: #444;  /* dark background to match the theme */
	color: #e0e0e0;  /* light font color for better readability on dark background */
}

table th, table td {
	border: 1px solid #666;  /* slightly lighter border to stand out against the table background */
	padding: 0.5rem;
	text-align: left;
}

table th {
	background-color: #555;  /* a shade darker than the table background for distinction */
	font-weight: bold;
}

table tbody tr:nth-child(odd) {
	background-color: #505050;  /* slightly different color for alternate rows to improve readability */
}

table tbody tr:hover {
	background-color: #3c3c3c;  /* darken rows on hover */
	cursor: pointer;  /* show that the row is interactive */
}

