#renderCanvas {
	width: 100%;
	height: calc(100% - 8px);
	position: relative;
	border: 3px solid rgba(0, 255, 136, 0.486);
	/* left: 0; */
}

#renderCanvas canvas:nth-of-type(2) {
	display: none;
}