.App {
	background-color: #333842;
	min-height: 100vh;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #333842;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

#page-content-wrapper {
	height: calc(100vh - 50px); /* Assuming height of TopMenu is 56px */
	width: 850px;
	float: left;
	overflow: hidden;
}

#sidebar-wrapper {
	height: calc(100vh - 50px);
	height: 100%;
	
}

#script-editor {
	width: 500px;
}

#thonktext {
	height: 500px;
}

.App {
	font-family: sans-serif;
	text-align: center;
}

playable {
	height: 90vh;
}

gametab {
	height: 100%;
}