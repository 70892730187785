.navbar {
	background-color: #6e7b94;
	border-radius: 0;
	padding: 5px 0; /* Added padding to give some spacing on top and bottom */
}

.navbar-brand {
	color: #ADD8E6 !important;
	font-size: 24px;	/* Reduced font size */
	border-radius: 0;
	padding: 0 15px; /* Control spacing around brand text */
	white-space: nowrap;
}

.nav-link {
	color: #9DA5B4 !important;
	font-size: 11px; /* Reduced font size a bit */
	border-radius: 0;
	padding: 5px 10px; /* Reduced padding for nav links */
	white-space: nowrap;
}

.nav-link:hover, .dropdown-toggle:hover {
	color: #fff !important;
	background-color: #454950 !important;
	border-radius: 0;
}

.dropdown-menu {
	background-color: #333842;
	border: none;
	border-radius: 0;
}

.dropdown-item {
	color: #9DA5B4 !important;
	border-radius: 0;
}

.dropdown-item:hover {
	color: #fff !important;
	background-color: #454950 !important;
	border-radius: 0;
}

.dropdown-toggle {
	border-radius: 0;
}

/* This styles the semi-transparent background */
.modal-backdrop.custom-modal {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
	z-index: 9999; /* Ensures the modal is on top */
}

/* This styles the actual modal/dialog */
.modal-backdrop.custom-modal .modal-dialog {
	width: 40%;	/* Set the width to 70% of the screen */
	max-width: 600px;	/* Maximum width of 600px */
	background-color: #6e7b94;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	/* Ensuring max height takes the entire viewport height */
	max-height: 100%;
	/* Vertically align to the middle */
	margin: auto 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 70vh;	/* 50% of the viewport height */
}


/* Modal Content */
.modal-content {
	height: 100%;
	overflow: hidden;	/* To ensure nothing spills out */
	color: #9DA5B4;
}

/* Modal Header */
.modal-content .modal-header {
	padding: 20px;
	border-bottom: 1px solid #e5e5e5;
}

.modal-content .modal-title {
	font-size: 24px; 
	color: #ADD8E6;
}

/* Close Button */
.modal-content .close {
	color: #ADD8E6;	/* Making the close button the same color as navbar-brand for consistency */
}

/* Close Button Hover */
.modal-content .close:hover {
	color: #fff;	/* Brighter color on hover */
}

/* Modal Body */
.modal-content .modal-body {
	padding: 20px;
	overflow-y: auto;
}

.modal-body h1 {
	font-size: 20px;
	margin-bottom: 15px;
}

/* Input Labels */
.modal-body label {
	display: block;
	margin-top: 10px;
	font-weight: bold;
	font-size: 14px;
	color: #ADD8E6;
}

/* Input Fields */
.modal-body input[type="text"] {
	width: 100%;
	padding: 5px 10px;
	margin-top: 5px;
	border: 1px solid #9DA5B4;
	border-radius: 4px;
	background-color: #878C9A;
	color: #093a94;
}

/* Modal Footer */
.modal-content .modal-footer {
	padding: 20px;
	border-top: 1px solid #e5e5e5;	
}

.modal-footer .btn {
	background-color: #454950;	/* Matched with navbar's hover color */
	color: #fff; /* White text for better contrast against the button's color */
	padding: 10px 20px;
}

.modal-footer .btn:hover {
	background-color: #35363F; /* Slightly darker background on hover */
}

.key-binding-row {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
}

.modal-footer-btn:not(:last-child) {
	margin-right: 10px; /* Adjust this value to your preference */
}

.keybindings-footer {
	display: flex;
	justify-content: center;	/* Horizontally center the buttons */
	align-items: center;			/* Vertically center the buttons (if needed) */
}

