

/* Basic reset for styles */
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(159, 184, 253, 0.945);
  }
  
  body, html {
	height: 100vh;
  }
  
  /* Container for the entire layout */
  .layout {
	display: grid;
	grid-template-rows: auto 1fr auto;
	grid-template-columns: 1fr 3fr;
	gap: 10px;
	height: 100%;
  }
  
  .landing {
	height: 100vh;
	width: 100vw;
  }

  /* Header */
  .header {
	grid-column: 1 / -1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
  }
  
  /* Cool Header Art */
  .header-art {
	grid-row: 1;
	grid-column: 2 / 3;
  }
  
  /* Authentication section */
  .auth {
	grid-row: 1;
	grid-column: 3 / 4;
  }
  
  /* Microblog section */
  .microblog {
	grid-row: 2 / 3;
	grid-column: 1 / 2;
  }
  
  /* Player Picker section */
  .player-picker {
	grid-row: 2 / 3;
	grid-column: 2 / 4;
	color: black;
  }
  
  /* Links section */
  .links {
	grid-row: 3 / 4;
	grid-column: 1 / 2;
  }
  
  /* Create New Player section */
  .create-player {
	grid-row: 3 / 4;
	grid-column: 2 / 3;
  }
  
  /* Footer */
  .footer {
	grid-column: 1 / -1;
	padding: 10px;
  }
  
  /* General card style */
  .card {
	background: #fff;
	border: 1px solid #ddd;
	padding: 10px;
	border-radius: 5px;
  }
  
  .selected-player {
	font-weight: bold;
  }

  .deselected-player {
	font-weight: normal;
  }

  /* Apply card style to sections */
  .header, .microblog, .player-picker, .links, .create-player, .footer {
	/* This will apply the .card styles to these sections */
  }
